.card_body {
	float: left;
	width: 65%;
	/* padding-bottom: 12px; */
	font-size: 13px;
	/* top: -4px; */
	/* position: relative; */
    display: flex;
    align-items: center;
}

.card_date_wrapper {
	float: left;
	width: 35%;
	text-align: right;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.card_date {
	font-size: 12px;
	/* position: relative; */
	/* top: -8px; */
    display: flex;
    align-items: center;
}

.card_icon {
	margin-left: 10px;
	/* position: relative; */
	/* top: -6px; */
}

/* Accordion */
.toggle {
	background-color: #F05622;
	color: #FFFFFF;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

.collapse {
	background-color: #F05623;
	color: #FFFFFF;
	opacity: 0.8;
	height: 15px;
}

.div_title {
	float: left;
	width: 95%;
	padding-left: 10px;
}

.title {
	padding-left: 10px;
	font-size: 13px;
	font-weight: 800;
}

.arrow {
	float: left;
	width: 5%;
	text-align: right;
	padding-right: 10px;
}
/* Emd of Accordion */