.success {
    padding: 56px 20px;
    min-height: 100%;
    width: 100%;
}

.title {
    text-align: center;
}

.active {
    font-size: 11px;
    font-weight: 400;
    background-color: #9cc96b;
    color: #fff;
}

.inactive {
    font-size: 11px;
    font-weight: 400;
    background-color: #e7e955;
    color: #404044;
}

.error {
    font-size: 11px;
    font-weight: 400;
    background-color: #e44959;
    color: #fff;
}

.btnContainer:hover {
    background-color: rgb(247, 248, 252);
    cursor: pointer;
}

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    font-size: 0.7em;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    border-radius: 2px;
    font-family: "Days One";
    text-transform: uppercase;
}
