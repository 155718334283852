.wrapper {
	border: 1px solid red; ;
}

.active {
	font-size: 15px;
	font-weight: 400;
	background-color: #9cc96b;
	color: #fff; 
  }

  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    font-size: .7em;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: initial;
    border-radius: 2px;
    font-family: "Days One";
    text-transform: uppercase;
}
